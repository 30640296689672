import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  MedicationStatusEnum,
  TreatmentType,
  useGetTreatmentQuery,
} from '@pm/graphql';
import {
  Centered,
  EmptyState,
  LayoutWithBackButton,
  LoadingPage,
  RouteLayoutWithBackLink,
  useQuestionnaire,
} from '@pm/core';
import { InteractiveCard, Text, useConfirmationModal, useToast } from '@pm/ui';
import { FormRadioGroup, FormSubmitButton } from '@pm/forms';
import { QuestionnaireDefinitionName } from '../../../types';
import { BackButtonModal } from './BackButtonModal';
import { PrepTreatmentRouteMap } from '../route-map';

export const PrepTreatment = () => {
  const { t } = useTranslation('treatments', { keyPrefix: 'PrepTreatment' });
  const navigate = useNavigate();
  const { data, loading, error } = useGetTreatmentQuery({
    variables: { treatmentType: TreatmentType.PrepTreatment },
    fetchPolicy: 'cache-and-network',
  });
  const treatment = data?.treatment;
  const prevRoute = '/';
  const { negative } = useToast();
  const {
    handleState,
    showConfirmationModal,
    handleConfirm,
    handleClose,
    confirm,
  } = useConfirmationModal();

  const startQuestionnaireCallbacks = {
    onSuccess: (responseId: string) => navigate(`/responses/${responseId}`),
    onError: () =>
      negative({
        content: t('Errors.CouldNotChange'),
      }),
  };
  const { startQuestionnaire: startStopPrepTreatmentReasonQuestionnaire } =
    useQuestionnaire({
      definitionName: QuestionnaireDefinitionName.StopPrepTreatmentReason,
      ...startQuestionnaireCallbacks,
    });
  const { startQuestionnaire: startPausePrepTreatmentReasonQuestionnaire } =
    useQuestionnaire({
      definitionName: QuestionnaireDefinitionName.PausePrepTreatmentReason,
      ...startQuestionnaireCallbacks,
    });

  const shouldShowError = error || !treatment;

  const schema = z.object({
    treatmentPlan: z.nativeEnum(MedicationStatusEnum).optional(),
  });

  const methods = useForm({
    values: { treatmentPlan: treatment?.medicationStatus },
    resolver: zodResolver(schema),
  });
  const selectedTreatmentPlan = methods.watch('treatmentPlan');

  if (loading) {
    return <LoadingPage />;
  }

  if (shouldShowError) {
    return (
      <RouteLayoutWithBackLink backTo={prevRoute}>
        <Centered>
          <EmptyState
            heading={t('Errors.NotFound.Title')}
            description={t('Errors.NotFound.Message')}
          />
        </Centered>
      </RouteLayoutWithBackLink>
    );
  }

  const handleBackButtonClick = async () => {
    if (
      selectedTreatmentPlan &&
      selectedTreatmentPlan !== treatment.medicationStatus
    ) {
      handleState();
      const confirmed = await confirm();
      if (confirmed) {
        return methods.handleSubmit((data) =>
          handleNextStep(data.treatmentPlan),
        )();
      }
    }
    navigate(prevRoute);
  };

  const handleNextStep = (treatmentPlan?: MedicationStatusEnum) => {
    switch (treatmentPlan) {
      case MedicationStatusEnum.Active:
        return navigate(
          PrepTreatmentRouteMap.restartTreatment.build({
            treatmentType: 'prep',
          }),
        );
      case MedicationStatusEnum.Paused:
        return startPausePrepTreatmentReasonQuestionnaire();
      case MedicationStatusEnum.Stopped:
        return startStopPrepTreatmentReasonQuestionnaire();
    }
  };

  return (
    <LayoutWithBackButton onBackButtonClick={handleBackButtonClick}>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit((data) =>
            handleNextStep(data.treatmentPlan),
          )}
          className="space-y-l"
        >
          <div className="space-y-xxxl">
            <FormRadioGroup
              name="treatmentPlan"
              label={<Text size="headingS">{t('Title')}</Text>}
              RadioComponent={InteractiveCard}
              space="m"
              options={[
                {
                  title: t('Selector.Active.Title'),
                  value: MedicationStatusEnum.Active,
                  checked:
                    selectedTreatmentPlan === MedicationStatusEnum.Active,
                  children: (
                    <Text size="bodyS">{t('Selector.Active.Body')}</Text>
                  ),
                  size: 'small',
                  'aria-label': 'Active',
                },
                {
                  title: t('Selector.Pause.Title'),
                  value: MedicationStatusEnum.Paused,
                  checked:
                    selectedTreatmentPlan === MedicationStatusEnum.Paused,
                  children: (
                    <Text size="bodyS">{t('Selector.Pause.Body')}</Text>
                  ),
                  size: 'small',
                  'aria-label': 'Pause',
                },
                {
                  title: t('Selector.Stop.Title'),
                  value: MedicationStatusEnum.Stopped,
                  checked:
                    selectedTreatmentPlan === MedicationStatusEnum.Stopped,
                  children: <Text size="bodyS">{t('Selector.Stop.Body')}</Text>,
                  size: 'small',
                  'aria-label': 'Stop',
                },
              ]}
            />
            <FormSubmitButton
              width="full"
              disabled={selectedTreatmentPlan === treatment.medicationStatus}
            >
              {t('ClinicalRecommendations.Next')}
            </FormSubmitButton>
          </div>
        </form>
      </FormProvider>

      <BackButtonModal
        showConfirmationModal={showConfirmationModal}
        handleState={handleState}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
    </LayoutWithBackButton>
  );
};
