import { ComponentProps, ElementType, useEffect, useId, useState } from 'react';
import { useController } from 'react-hook-form';
import { errorMessage, formField, supportingMessage } from './FormField';
import {
  InputLabel,
  InteractiveCard,
  RadioField,
  RadioGroup,
  SingleSelect,
} from '@pm/ui';

type Option =
  | ComponentProps<typeof RadioField>
  | ComponentProps<typeof SingleSelect>
  | ComponentProps<typeof InteractiveCard>;

type FormRadioGroupProps = Omit<
  ComponentProps<typeof RadioGroup>,
  'children'
> & {
  label?: React.ReactNode;
  name: string;
  options: Option[];
  supportingText?: string;
  RadioComponent: ElementType;
  freeText?: {
    on: string;
    placeholder?: string;
    label?: React.ReactNode;
    name: string;
  };
};

/**
 * Component wrapper for radio group elements to be used with React Hook Forms.
 */
export const FormRadioGroup = ({
  label,
  name,
  options,
  supportingText,
  RadioComponent,
  freeText,
  space,
  ...restProps
}: FormRadioGroupProps) => {
  const { field, fieldState } = useController({ name });
  const { error } = fieldState;
  const errorId = error ? `${name}-error` : undefined;
  const generatedLabelId = useId();
  const labelId = label ? generatedLabelId : undefined;
  const [freeTextSelected, setFreeTextSelected] = useState(false);
  const [freeTextValue, setFreeTextValue] = useState('');

  useEffect(() => {
    let isFreeTextSelected = false;
    const isOptionSelected = options.some((option) => {
      if (field.value === '' && errorId) {
        return false;
      }
      if (field.value === option.value) {
        return true;
      }
    });

    if (freeText && field.value === freeTextValue && !isOptionSelected) {
      isFreeTextSelected = true;
    }
    setFreeTextSelected(isFreeTextSelected);
    if (!isOptionSelected) {
      setFreeTextValue(field.value);
    }
  }, [
    setFreeTextSelected,
    field.value,
    freeText,
    options,
    freeTextValue,
    freeTextSelected,
    errorId,
  ]);

  return (
    <div className={formField({ space })}>
      {label && <InputLabel id={generatedLabelId}>{label}</InputLabel>}
      <RadioGroup
        space={space}
        aria-describedby={errorId}
        aria-label={restProps['aria-label']}
        aria-labelledby={labelId ?? restProps['aria-labelledby']}
      >
        {options.map((radioProps) => (
          <RadioComponent
            {...field}
            {...radioProps}
            checked={
              radioProps.value
                ? field.value === radioProps.value
                : freeTextSelected
            }
            key={`${name}-${radioProps.value}`}
            onClick={() => {
              if (radioProps.value !== '') {
                field.onChange(radioProps.value);
              } else {
                field.onChange(freeTextValue, {
                  shouldTouch: true,
                  shouldValidate: true,
                  shouldDirty: true,
                });
                setFreeTextSelected(true);
              }
            }}
            ref={null}
            freeText={
              freeText &&
              freeTextSelected &&
              !radioProps.value && {
                ...freeText,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  field.onChange(event.target.value);
                  setFreeTextValue(event.target.value);
                },
                value: field.value,
                name,
              }
            }
          />
        ))}
      </RadioGroup>
      {error && (
        <p id={errorId} className={errorMessage()}>
          {error.message}
        </p>
      )}
      {supportingText && (
        <span className={supportingMessage()}>{supportingText}</span>
      )}
    </div>
  );
};
