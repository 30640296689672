import { Icon, IconName, Text } from '@pm/ui';

export const IconListItem = ({
  icon,
  iconColor = 'text-content-default',
  textContent,
}: {
  icon: IconName;
  iconColor?: string;
  textContent?: string;
}) => {
  return (
    <div className="flex flex-row gap-x-m items-center">
      <Icon name={icon} color={iconColor} />
      <Text size="bodyL">{textContent}</Text>
    </div>
  );
};
