import { ButtonLink, Text, useIsMobile } from '@pm/ui';
import { useTranslation } from 'react-i18next';

export const NotFound = () => {
  const { t } = useTranslation(['global', 'not-found-page']);
  const { isMobile } = useIsMobile();
  const buttonsSize = isMobile ? 'large' : 'small';

  return (
    <div className="space-y-xxxl mt-xxxl max-w-[624px] sm:px-4 px-1 mx-auto">
      <div className="space-y-l">
        <Text size="headingL">{t('not-found-page:Title')}</Text>
        <Text size="bodyL">{t('not-found-page:ContactUsDescription')}</Text>
      </div>
      <div className="space-y-m sm:flex flex-col items-start">
        <div>
          <ButtonLink to="/" size={buttonsSize}>
            {t('not-found-page:BackToTreatmentDetails')}
          </ButtonLink>
        </div>
        <div>
          <ButtonLink
            to={t('SupportLinks.MailTo')}
            size={buttonsSize}
            intent="ghost"
            width="full"
          >
            {t('not-found-page:ContactUs')}
          </ButtonLink>
        </div>
      </div>
    </div>
  );
};
