import { useState } from 'react';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  HookFormSubmitButton,
  HookFormStyledField,
  HookFormAttachmentUploader,
} from '@pm/core';
import { useCreateMessageMutation } from '@pm/graphql';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Attachment } from '../Attachments';

type ReplyMessageFormProps = {
  toId: string;
  userId: string;
  messageId: string;
  subject: string;
};

const ReplyMessageForm = ({
  toId,
  userId,
  messageId,
  subject,
}: ReplyMessageFormProps) => {
  const [send] = useCreateMessageMutation();
  const { t } = useTranslation('messages');
  const [attachments, setAttachments] = useState<Attachment[]>([]);

  const schema = Yup.object({
    content: Yup.string().required('You must enter a message'),
    attachments: Yup.mixed().test(
      'attachmentsUploaded',
      'Attachments need to finish uploading',
      () => attachments.every((attachment) => !!attachment.blob),
    ),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      content: '',
    },
  });

  const onSubmit = methods.handleSubmit(async (data) => {
    return send({
      variables: {
        input: {
          senderId: userId,
          recipientId: toId,
          parentId: messageId,
          subject: subject,
          content: data.content,
          attachments: attachments.map(
            (attachment) => attachment.blob?.signed_id ?? '',
          ),
        },
      },
      refetchQueries: ['GetConversation'],
      awaitRefetchQueries: true,
    }).then(() => {
      methods.reset();
      setAttachments([]);
      toast.success('Message sent');
    });
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <div className="space-y-4">
          <Controller
            control={methods.control}
            name="content"
            render={({ field: { value } }) => (
              <HookFormStyledField
                type="textarea"
                value={value}
                name="content"
                labelStyle="text-grey-500 mb-0 text-sm font-medium"
                placeholder="Respond to this message"
                label={t('Reply') || 'Reply'}
                as="textarea"
              />
            )}
          />
          <HookFormAttachmentUploader
            label={t('Attachments') || 'Attachments'}
            name="attachments"
            attachments={attachments}
            setAttachments={setAttachments}
            placeholder={t('Drag') || ''}
          />
          <div className="flex">
            <HookFormSubmitButton>{t('Send')}</HookFormSubmitButton>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export { ReplyMessageForm };
