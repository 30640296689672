import { type QuestionnaireRadioQuestion as GQLQuestionnaireRadioQuestion } from '@pm/graphql';
import { CardText } from '../CardText';
import { QuestionnaireSection } from './QuestionnaireSection';
import { QuestionnaireMarkdown } from '../QuestionnaireMarkdown/QuestionnaireMarkdown';
import { FormRadioGroup } from '@pm/forms';
import { SingleSelect } from '@pm/ui';

type QuestionnaireRadioGroupQuestionProps = GQLQuestionnaireRadioQuestion;

export const QuestionnaireRadioGroupQuestion = ({
  id,
  acceptableAnswers = [],
  title,
  description,
}: QuestionnaireRadioGroupQuestionProps) => {
  const radioOptions = acceptableAnswers.map((acceptableAnswer) => ({
    header: acceptableAnswer.label.split(/\n(.*)/s)[0],
    body: acceptableAnswer.label.split(/\n(.*)/s)[1] ?? '',
    value: acceptableAnswer.value ?? '',
  }));

  const freeTextValue = acceptableAnswers.find(
    (answer) => answer.value === null,
  )?.label;

  return (
    <QuestionnaireSection>
      <CardText className="text-grey-900 text-xl">{title}</CardText>
      {description && (
        <CardText>
          <QuestionnaireMarkdown>{description}</QuestionnaireMarkdown>
        </CardText>
      )}
      <FormRadioGroup
        name={id}
        aria-label={title ?? ''}
        aria-labelledby={title ?? ''}
        options={radioOptions}
        freeText={{
          on: freeTextValue ?? '',
          name: id,
        }}
        RadioComponent={SingleSelect}
      />
    </QuestionnaireSection>
  );
};
