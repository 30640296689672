import { TreatmentType } from '@pm/graphql';
import { usePurposemedFlags } from '../../../components/FeatureFlag/FeatureFlag';
import {
  QuestionnaireDefinitionName,
  QuestionnaireDefinition,
} from '../../../types';

export type TreatmentCardItem = {
  label: string;
  description: string;
  externalValue: string;
  page: number;
  getStarted?: boolean;
  infoModal?: boolean;
  formRoute?: () => string;
  questionnaire?: QuestionnaireDefinition;
  type?: TreatmentType;
};

type UseAdditionalTreatmentsProps = {
  available?: boolean;
};

export const useAdditionalTreatments = ({
  available = false,
}: UseAdditionalTreatmentsProps) => {
  const {
    treatmentHsv,
    treatmentEd,
    treatmentPe,
    treatmentHairLoss,
    treatmentSti,
  } = usePurposemedFlags();

  return [
    treatmentSti
      ? {
          label: 'STI Prevention',
          description: 'Doxy PEP lowers risk for bacterial STIs.',
          externalValue: 'STI prevention (Doxy-PEP)',
          getStarted: available,
          infoModal: true,
          page: 19,
          questionnaire: QuestionnaireDefinitionName.DoxyPep,
          type: TreatmentType.DoxyPepTreatment,
        }
      : undefined,
    treatmentEd
      ? {
          label: 'Erectile Dysfunction',
          description: 'Solve erection problems with medication.',
          externalValue: 'Erectile dysfunction',
          getStarted: available,
          infoModal: false,
          page: 11,
          formRoute: function () {
            return `/embedded-form/240795642801257/${this.page}`;
          },
        }
      : undefined,
    treatmentPe
      ? {
          label: 'Premature Ejaculation',
          description: 'Tools to help you last longer.',
          externalValue: 'Premature ejaculation',
          getStarted: available,
          infoModal: false,
          page: 15,
          formRoute: function () {
            return `/embedded-form/240795642801257/${this.page}`;
          },
        }
      : undefined,
    treatmentHsv
      ? {
          label: 'Herpes (HSV) Treatment',
          description: 'Treat outbreaks and prevent future ones.',
          externalValue: 'Herpes (HSV) treatment',
          getStarted: available,
          infoModal: false,
          page: 3,
          formRoute: function () {
            return `/embedded-form/240795642801257/${this.page}`;
          },
        }
      : undefined,
    treatmentHairLoss
      ? {
          label: 'Hair Loss Prevention',
          description: 'Medication to stop hair loss.',
          externalValue: 'Hair loss prevention',
          getStarted: available,
          infoModal: false,
          page: 7,
          formRoute: function () {
            return `/embedded-form/240795642801257/${this.page}`;
          },
        }
      : undefined,
  ].filter((t) => t !== undefined) as TreatmentCardItem[];
};
