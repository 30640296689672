import { DosingRegimenEnum } from '@pm/graphql';

export const testFreddiePrescription = {
  __typename: 'Prescription',
  brand: {
    __typename: 'Brand',
    // Freddie brand
    id: '1',
    name: 'Freddie',
  },
  additionalInfo: null,
  bridging: null,
  createdAt: new Date().toISOString(),
  deleteOperation: null,
  dosage: null,
  dosingRegimen: DosingRegimenEnum.Daily,
  dueAt: null,
  fulfillmentMethod: null,
  id: null,
  medication: null,
  message: null,
  numOfPills: null,
  prescriptionLength: null,
  prescriptionType: null,
  readByPatientAt: null,
  sentBy: null,
  treatmentStartAt: null,
  updateOperation: null,
  prescribedDate: null,
  prepType: 'Descovy',
  sentAt: '',
};

export const testFridaPrescription = {
  __typename: 'Prescription',
  brand: {
    __typename: 'Brand',
    // Frida brand
    id: '2',
    name: 'Frida',
  },
  additionalInfo: null,
  bridging: null,
  createdAt: new Date().toISOString(),
  deleteOperation: null,
  dosage: null,
  dosingRegimen: DosingRegimenEnum.Daily,
  dueAt: null,
  fulfillmentMethod: null,
  id: null,
  medication: null,
  message: null,
  numOfPills: null,
  prescriptionLength: null,
  prescriptionType: null,
  readByPatientAt: null,
  sentBy: null,
  treatmentStartAt: null,
  updateOperation: null,
  prescribedDate: null,
  prepType: null,
  sentAt: '',
};

export const testProfile = {
  allergies: null,
  ccLastFour: null,
  dateOfBirth: '1987-08-03',
  dateVerified: null,
  deliveryPreference: null,
  financialExpectation: 'Trillium Drug Program (TDP)',
  hasPaymentMethod: false,
  healthCareNumber: '606667077',
  medicalHistoryVersions: [],
  healthCareProvince: {
    __typename: 'ProvinceState',
    id: '1',
    name: 'Alberta',
    code: 'AB',
    supported: true,
  },
  id: '5',
  insuranceCardConfirmedAt: null,
  insuranceCardPhotos: [],
  insuranceCards: [],
  medInfoConfirmedAt: null,
  medicalConditions: null,
  medications: null,
  province: {
    __typename: 'ProvinceState',
    id: '9',
    name: 'Ontario',
    code: 'ON',
    supported: true,
  },
  shippingAddresses: [],
  shippingConfirmedAt: null,
  stripeCustomerId: null,
  stripeVerificationSessionId: null,
  verificationStatus: null,
  lastStripePaymentMethod: null,
  lastAffirmPaymentMethod: null,
};

export const testUserParams = {
  acuityAppointments: {
    nodes: [],
  },
  assessments: {
    nodes: [],
  },
  brands: [],
  createdAt: new Date().toISOString(),
  deactivatedAt: null,
  email: 'han.solo@gofreddie.com',
  emrId: null,
  firstName: 'Han',
  id: '20',
  journeyItems: [],
  labRequisitions: {
    nodes: [],
  },
  lastName: 'Solo',
  locale: 'en',
  phone: {
    e164: '+14038881234',
    rawNumber: '+14038881234',
  },
  preferredName: null,
  prescriptions: { nodes: [] },
  profile: testProfile,
  questionnaires: [],
  shortQuestionnaires: [],
  smsEnabled: true,
  lastTreatments: [],
  treatment: {},
  vitals: { nodes: [] },
  voicemailConsent: true,
  zendeskId: null,
};

export const createPatientDocumentQueryResult = (
  userParams: Record<string, unknown> = {},
) => {
  return {
    data: {
      user: {
        ...testUserParams,
        ...userParams,
      },
    },
  };
};
