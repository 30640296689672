import { pick } from './utilities/pick';

export const BRAND_NAME = {
  Freddie: 'freddie',
  FreddieUSA: 'freddie usa',
  Frida: 'frida',
  PurposeMed: 'purposemed',
} as const;

export type Brand = (typeof BRAND_NAME)[keyof typeof BRAND_NAME];

export enum BrandId {
  Freddie = '1',
  Frida = '2',
}

export enum KeyboardKeys {
  Enter = 'Enter',
}

export type FormattingContext = 'patient' | 'provider';

/**
 * ISO 8601 date string
 * @example '2020-01-01'
 */
export type ISO8601Date = string;

/**
 * ISO 8601 datetime string
 * @example '2020-01-01T00:00:00.000Z'
 */
export type ISO8601DateTime = string;

export type SupportedLanguage = 'en' | 'fr' | (string & {});
export const QuestionnaireDefinitionName = {
  AsyncPrep: 'async_prep_questionnaire',
  StopPrepTreatmentReason: 'stop_prep_treatment_reason_questionnaire',
  PausePrepTreatmentReason: 'pause_prep_treatment_reason_questionnaire',
  DoxyPep: 'doxy_pep_questionnaire',
  PrepIntake: 'intake_questionnaire',
} as const;

export type QuestionnaireDefinition =
  (typeof QuestionnaireDefinitionName)[keyof typeof QuestionnaireDefinitionName];

export type NonNullableVariantProps<T> = {
  [K in keyof T]: Exclude<T[K], null>;
};

export type StripWhiteSpace<T> = T extends `${infer U} ${infer V}`
  ? `${U}${StripWhiteSpace<V>}`
  : T;

export const ROLE = {
  Patient: 'PATIENT',
  Clinician: 'CLINICIAN',
  CX: 'CX',
  MOA: 'MOA',
  SuperUser: 'ADMIN',
  Pharmacy: 'PHARMACY',
} as const;

export type Role = (typeof ROLE)[keyof typeof ROLE];

export const ProviderRoles = pick(ROLE, 'Clinician', 'CX', 'MOA', 'SuperUser');

export const PROVIDER_ROLES = [
  ROLE.Clinician,
  ROLE.CX,
  ROLE.MOA,
  ROLE.SuperUser,
  ROLE.Pharmacy,
];
