import { NewWindowIcon } from '@pm/icons';
import { TextLink } from '../TextLink/TextLink';
import ReactMarkdown from 'react-markdown';
import invariant from 'tiny-invariant';

type QuestionnaireMarkdownProps = {
  children: string;
};

export const QuestionnaireMarkdown = ({
  children,
}: QuestionnaireMarkdownProps) => {
  return (
    <ReactMarkdown
      components={{
        p: ({ node, ...props }) => <div {...props} />,
        em: ({ node, ...props }) => (
          <small
            {...props}
            style={{ fontSize: '0.75rem', lineHeight: '0rem' }}
          />
        ),
        ul: ({ node, ...props }) => (
          <ul {...props} className="list-disc pl-6" />
        ),
        a: ({ node, href, ...props }) => {
          invariant(
            href,
            'Href is required for anchor tags in questionnaire markdown descriptions',
          );
          // @note - https://github.com/purposemed/core/issues/8629 To be reverted after resources work
          return (
            <TextLink target="_blank" to={href} external>
              <div className="flex mt-2 items-center gap-1">
                <NewWindowIcon className="h-5 w-5" />
                {props.children}
              </div>
            </TextLink>
          );
        },
      }}
    >
      {children}
    </ReactMarkdown>
  );
};
