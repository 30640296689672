import { cva } from 'class-variance-authority';
import { WithChildren, WithDisabled } from '../../../types/component.types';
import { Text } from '../../Text/Text';
import { InputLabel } from '../InputLabel';
import { TextField, TextFieldProps } from '../TextField/TextField';

const selectorStyles = cva(
  'flex flex-col transition rounded-s ring-primary focus-within:ring group',
  {
    variants: {
      intent: {
        default:
          'hover:bg-hover-opacity hover:border-strong active:bg-surface-active border [&:has([data-state=checked])]:border-2 [&:has([data-state=checked])]:border-accent',
        disabled: 'bg-surface-disabled text-content-disabled',
      },
    },
    defaultVariants: {
      intent: 'default',
    },
  },
);

const checkboxStyles = cva(
  'flex-1 flex justify-between items-center space-x-s p-m',
  {
    variants: {
      intent: {
        default: 'cursor-pointer',
        disabled: 'cursor-not-allowed',
      },
    },
    defaultVariants: {
      intent: 'default',
    },
  },
);

const freeTextStyles = cva(
  'px-m pb-m flex-col gap-y-xxxs hidden group-[&:has([data-state=checked])]:flex space-y-xxs',
);

type SelectorProps = WithChildren &
  WithDisabled & {
    childId: string;
    body: string;
    header?: string;
    freeText?: TextFieldProps;
  };

export const Selector = ({
  childId,
  disabled,
  header,
  body,
  freeText,
  children,
}: SelectorProps) => {
  const intent = disabled ? 'disabled' : 'default';
  const bodyColor = () => {
    if (disabled) {
      return 'contentDisabled';
    }

    return header ? 'contentSubdued' : 'contentDefault';
  };

  return (
    <div className={selectorStyles({ intent })}>
      <label htmlFor={childId} className={checkboxStyles({ intent })}>
        <div>
          {header && (
            <Text size="bodyL" select="none">
              {header}
            </Text>
          )}
          <Text size="bodyL" select="none" color={bodyColor()}>
            {body}
          </Text>
        </div>
        {children}
      </label>
      {freeText && (
        <div className={freeTextStyles()}>
          <InputLabel htmlFor={`${childId}-custom`}>
            {freeText.label}
          </InputLabel>
          <TextField
            {...freeText}
            id={`${childId}-custom`}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  );
};
