import { type QuestionnaireCheckboxQuestion as GQLQuestionnaireCheckboxQuestion } from '@pm/graphql';
import { CardText } from '../CardText';
import { QuestionnaireSection } from './QuestionnaireSection';
import { HookFormCheckbox } from '../Checkbox/HookFormCheckbox';
import { QuestionnaireMarkdown } from '../QuestionnaireMarkdown/QuestionnaireMarkdown';
import { FormCheckboxGroup } from '@pm/forms';
import { MultiSelect } from '@pm/ui';

type QuestionnaireCheckboxQuestionProps = GQLQuestionnaireCheckboxQuestion;

export const QuestionnaireCheckboxQuestion = ({
  id,
  title,
  description,
  acceptableAnswers = [],
}: QuestionnaireCheckboxQuestionProps) => {
  const options = acceptableAnswers.map((answer) => ({
    text: answer.label,
    value: answer.value ?? answer.label,
  }));

  const multiSelectOptions = acceptableAnswers.map((answer) => ({
    header: answer.label.split(/\n(.*)/s)[0],
    body: answer.label.split(/\n(.*)/s)[1] ?? '',
    value: answer.value ?? '',
  }));

  const freeTextValue = acceptableAnswers.find(
    (answer) => answer.value === null,
  )?.label;

  const noneValue = acceptableAnswers.find(
    (answer) => answer.value === 'none',
  )?.value;

  return (
    <QuestionnaireSection>
      <CardText className="text-grey-900 text-xl">{title}</CardText>
      {description && (
        <CardText>
          <QuestionnaireMarkdown>{description}</QuestionnaireMarkdown>
        </CardText>
      )}
      {options.length === 1 ? (
        <HookFormCheckbox name={id} value={options[0].value}>
          {options[0].text}
        </HookFormCheckbox>
      ) : (
        <FormCheckboxGroup
          name={id}
          options={multiSelectOptions}
          CheckboxComponent={MultiSelect}
          noneValue={noneValue}
          freeText={{
            on: freeTextValue ?? '',
            name: id,
          }}
        />
      )}
    </QuestionnaireSection>
  );
};
