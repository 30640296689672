import {
  QuestionnaireQuestionResource,
  QuestionnaireQuestionResourceTypeEnum,
} from '@pm/graphql';
import { NewWindowIcon } from '@pm/icons';
import { TextLink } from '../TextLink/TextLink';
import { Text } from '@pm/ui';
import { QuestionnaireResourcesModal } from './QuestionnaireResourceModal';
import { useState } from 'react';
import { CardText } from '../CardText';
import { Card } from '../Card';

interface QuestionnaireResourcesProps {
  resources: QuestionnaireQuestionResource[] | null;
}

export const QuestionnaireResources = ({
  resources = [],
}: QuestionnaireResourcesProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  if (resources === null) {
    return null;
  }

  return resources.map((resource: QuestionnaireQuestionResource) => {
    if (resource.type === QuestionnaireQuestionResourceTypeEnum.Img) {
      return (
        <div key={resource.value} className={'w-full'}>
          <img
            key={resource.value}
            src={resource.value}
            alt={resource.label ?? ''}
          />
        </div>
      );
    } else if (resource.type === QuestionnaireQuestionResourceTypeEnum.Link) {
      return (
        <TextLink
          key={resource.value}
          target="_blank"
          to={resource.value}
          external
        >
          <div className="flex mt-2 items-center gap-1">
            <NewWindowIcon className="h-5 w-5" />
            {resource.label}
          </div>
        </TextLink>
      );
    } else if (resource.type === QuestionnaireQuestionResourceTypeEnum.Modal) {
      return (
        <div key={resource.value} className="w-full text-center py-4">
          <button
            type="button"
            aria-label={resource.label ?? ''}
            onClick={() => {
              setShowModal(true);
            }}
          >
            <Text size="buttonL" color="contentPositive">
              {resource.label ?? ''}
            </Text>
          </button>
          <QuestionnaireResourcesModal
            resource={resource}
            showModal={showModal}
            setShowModal={setShowModal}
          />
        </div>
      );
    } else if (resource.type === QuestionnaireQuestionResourceTypeEnum.Card) {
      return (
        <div key={resource.value} className="p-4">
          <Card className="space-y-l">
            <CardText className="text-grey-900 text-heading-xs">
              {resource.title}
            </CardText>
            <CardText>{resource.value}</CardText>
          </Card>
        </div>
      );
    }
    return;
  });
};
